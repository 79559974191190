import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import logo from "../assets/images/logo.png";
import Swal from "sweetalert2";
import Dropdown from "react-bootstrap/Dropdown";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import RenewLicenceModal from "../components/RenewLicenceModal";

const baseUrl = process.env.REACT_APP_BASE_URL


class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPremisesRegistered: false,
      isFoodScientistRegistered: false,
      isLicenceRegistered: "",
      isLicenceLoading: "",
      isInspectionLoading: false,
      isPremisesLoading: false,
      isInspectionRegistered: false,
      isUserInfoLoading: false,
      regNumber: "",
      showLicenceModal: false,
    };
  }


	handleToggle = () => {
    this.props.ToggleCollapesSidebar(false)
	}

  getPreviousPremisesRegistration = async () => {
    const url = `${baseUrl}/Premises/getPremisesByUserID/${localStorage.getItem(
      "userid"
    )}`;
    this.setState({ isLoading: true, isPremisesLoading: true });
    await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == 401) {
          this.props.history.push("/login");
        } else {
          this.setState({
            isPremisesRegistered: true,
            isPremisesLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: true,
          loading: false,
          isPremisesLoading: false,
        });
        // alert(error);
      });
  };

  checkLicenceRegistration = async () => {
    let obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await fetch(
      `${baseUrl}/License/getlicenseByUserID/${localStorage.getItem("userid")}`,
      obj
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 401) {
          this.setState({ isLicenceLoading: false });
          Swal.fire({
            title: "Session Expired",
            text: "Session expired. Please login",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            this.props.history.push("/login");
          });
        } else if (responseJson.length !== 0) {
          responseJson.licensenumber !== null
            ? this.setState({
                isLicenceLoading: false,
                licence: responseJson[0].licensenumber,
              })
            : this.setState({ isLicenceLoading: false });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  getUserDetails = async () => {
    this.setState({ isUserInfoLoading: true });
    let obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await fetch(
      `${baseUrl}/Registration/getRegistrationByUserID/${localStorage.getItem(
        "userid"
      )}`,
      obj
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 401) {
          this.setState({ isUserInfoLoading: false });
          Swal.fire({
            title: "Session Expired",
            text: "Session expired. Please login",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            this.props.history.push("/login");
          });
        } else {
          responseJson.map((item) => {
            this.setState({
              regNumber: item.registrationnumber,
              isLicenceRegistered: item.applicationstatus,
              isUserInfoLoading: false,
            });
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  handleLicenceRegistration = () => {
    if (this.state.isLicenceRegistered !== "approved") {
      Swal.fire({
        title: "Error!",
        text: "You can not apply for licence, since your registration approval is pending.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  showRenewLicenceModal = (val) => {
    this.setState({ showLicenceModal: val });
  };

  componentDidMount() {
    this.getPreviousPremisesRegistration();
    this.checkLicenceRegistration();
    this.getUserDetails();
  }

  render() {
    const {
      isPremisesRegistered,
      regNumber,
      isInspectionRegistered,
      isLicenceLoading,
      isPremisesLoading,
      isFoodScientistRegistered,
      isLicenceRegistered,
      isSidebarCollapsed,
      showSidebar,
      licence,
    } = this.state;

    console.log(this.state.showSidebar, 'toggle sidebar');

    return (
      <div className="g-sidenav-show" style={{ padding: 0, margin: 0 }}>
        <aside
          className="sidenav navbar navbar-vertical navbar-expand-xs  fixed-start"
          id="sidenav-main"
          style={{
            margin: "0 !important",
            borderRadius: "0",
            overflow: "hidden",
          }}
        >
          <div
            className="sidenav-header"
            style={{ backgroundColor: "#00264C" }}
          >
            <i
              className="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
              aria-hidden="true"
              id="iconSidenav"
            ></i>
            <a
              className="navbar-brand d-flex justify-content-center text-center m-0"
              href="#"
              target="_blank"
            >
              <img
                src={logo}
                className="navbar-brand-img h-100"
                alt="main_logo"
              />
            </a>
          </div>
          <hr className="horizontal light mt-0 mb-0" />

          <div
            className="collapse navbar-collapse  w-auto  max-height-vh-100"
            id="sidenav-collapse-main"
            style={{ height: "150vh", backgroundColor: "#00264C" }}
          >
            <ul className="navbar-nav">

              <li class="nav-item">
                <Link
                  to="/dashboard"
                  className={
                    window.location.href.toString().includes("dashboard")
                      ? "active nav-link"
                      : "nav-link"
                  }
                >
                  <div class="text-center me-2 d-flex align-items-center justify-content-center">
                    <i
                      style={{ position: "relative", left: 0, padding: 6 }}
                      class="material-icons opacity-10 text-light"
                    >
                      dashboard
                    </i>
                  </div>
                  <span
                    style={{ position: "relative", left: 0, fontSize: 16 }}
                    className={
                      window.location.href.toString().includes("dashboard")
                        ? "nav-link-text ms-1 text-light"
                        : "nav-link-text ms-1 text-light"
                    }
                  >
                    Dashboard
                  </span>
                </Link>
              </li>

              <li>
                <div className="nav-link pl-1">
                  <Dropdown>
                    <div
                      onClick={this.handleLicenceRegistration}
                      style={{ fontSize: 16 }}
                      className="btn btn-lg text-capitalize p-0 text-light font-weight-light"
                      variant={
                        window.location.href
                          .toString()
                          .includes("register-food-scientist")
                          ? "success"
                          : window.location.href
                              .toString()
                              .includes("renew-licence")
                          ? "success"
                          : window.location.href
                              .toString()
                              .includes("food-scientist-record")
                          ? "success"
                          : "secondary"
                      }
                      id="dropdown-basic"
                    >
                      <i
                        style={{ position: "relative", left: -6, padding: 6 }}
                        className="material-icons opacity-10 font-weight-light"
                      >
                        how_to_reg
                      </i>{" "}
                      Licence
                    </div>

                    <div className="pl-2" style={{ marginLeft: "35px" }}>
                      {/* {regNumber === "" ? <Dropdown.Item><Link to="/register-food-scientist" className="text-decoration-none py- text-light">Apply For Registration</Link></Dropdown.Item> 
           : <Dropdown.Item><Link to="/food-scientist-record" className="text-decoration-none py- text-light font-weight-bold">Registration Application</Link></Dropdown.Item> 
          } */}
                      {isLicenceRegistered === "approved" && licence ? (
                        <>
                          <Dropdown.Item className="py-3" active>
                            <Link
                              to="/licence-history"
                              className="text-decoration-none py- text-light"
                            >
                              Licence History
                            </Link>
                          </Dropdown.Item>
                          <RenewLicenceModal>Renew Licence</RenewLicenceModal>
                        </>
                      ) : (
                        isLicenceRegistered === "approved" && (
                          <Dropdown.Item className="py-3">
                            <Link
                              to="/register-licence"
                              className="text-decoration-none py- text-light"
                            >
                              Apply For Licence
                            </Link>
                          </Dropdown.Item>
                        )
                      )}
                    </div>
                  </Dropdown>
                </div>
              </li>

              <li
                class="nav-item"
                style={{ position: "relative", top: -27, left: 0 }}
              >
                <Link
                  to="/manage-inspections"
                  className={
                    window.location.href
                      .toString()
                      .includes("manage-inspections")
                      ? "active nav-link"
                      : "nav-link"
                  }
                >
                  <div class="text-center me-2 d-flex align-items-center justify-content-center">
                    <i
                      style={{ position: "relative", left: 0 }}
                      class="material-icons opacity-10 text-light"
                    >
                      business
                    </i>
                  </div>
                  <span
                    style={{ position: "relative", left: 0, fontSize: 16 }}
                    className={
                      window.location.href
                        .toString()
                        .includes("manage-inspections")
                        ? "nav-link-text ms-1 text-light"
                        : window.location.href
                            .toString()
                            .includes("manage-inspections")
                        ? ""
                        : "nav-link-text ms-1 text-light"
                    }
                  >
                    Premises
                  </span>
                </Link>
              </li>

              <li
                class="nav-item"
                style={{ position: "relative", top: -36, left: 0 }}
              >
                <Link
                  to="/payments"
                  className={
                    window.location.href.toString().includes("payments")
                      ? "active nav-link"
                      : window.location.href
                          .toString()
                          .includes("food-scientist-payment")
                      ? "active nav-link"
                      : "nav-link"
                  }
                >
                  <div class="text-center me-2 d-flex align-items-center justify-content-center">
                    <i
                      style={{ position: "relative", left: 0 }}
                      class="material-icons opacity-10 text-light"
                    >
                      credit_card
                    </i>
                  </div>
                  <span
                    style={{ position: "relative", left: 0, fontSize: 16 }}
                    className={
                      window.location.href.toString().includes("payments")
                        ? "nav-link-text ms-1 text-light"
                        : window.location.href
                            .toString()
                            .includes("food-scientist-payment")
                        ? ""
                        : "nav-link-text ms-1 text-light"
                    }
                  >
                    Payments
                  </span>
                </Link>
              </li>

              <li
                class="nav-item"
                style={{ position: "relative", top: -45, left: 0 }}
              >
                <Link
                  to="/logout"
                  className={
                    window.location.href.toString().includes("logout")
                      ? "active nav-link"
                      : "nav-link"
                  }
                >
                  <div class="text-center me-2 d-flex align-items-center justify-content-center">
                    <i
                      style={{ position: "relative", left: 0 }}
                      class="material-icons opacity-10 text-light"
                    >
                      logout
                    </i>
                  </div>
                  <span
                    style={{ position: "relative", left: 0, fontSize: 16 }}
                    className={
                      window.location.href.toString().includes("logout")
                        ? "nav-link-text ms-1 text-light"
                        : "nav-link-text ms-1 text-light"
                    }
                  >
                    Logout
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </aside>

        <Navbar
          expand="lg"
          className={`d-block d-md-none sm-mobile-sidebar  ${ this.props.isOpen ? 'd-block' : 'd-none'} `}
          variant="dark"
          style={{ backgroundColor: "#00264C" }}
        >
          <Container style={{ backgroundColor: "#00264C", padding: 18 }}>
            <Navbar.Brand className="text-light font-weight-bold">
              NiCFoST
            </Navbar.Brand>
            <Navbar.Toggle  onClick={this.handleToggle} className=" bg-dark" />
            
            <nav  id="none">
              <Nav
                className="me-auto my-2 my-lg-0"
                // style={{ maxHeight: "100px" }}
                navbarScroll
              >
                <Nav.Link className="text-light">
                  <Link
                    className="text-light text-decoration-none py-"
                    to="/dashboard"
                  >
                    Dashbord
                  </Link>
                </Nav.Link>
                <NavDropdown
                  title="Food Scientist"
                  className="text-light"
                  id="navbarScrollingDropdown"
                >
                  <NavDropdown.Item variant="light" className="text-light">
                    {/* {this.state.regNumber && <Link to="/register-food-scientist" > Apply For Registration</Link> 
                // :  <Link to="/food-scientist-record" >Registration Application</Link> 
              } */}
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  style={{ color: "white" }}
                  title="License"
                  className="text-light"
                  id="navbarScrollingDropdown"
                >
                </NavDropdown>

                {isLicenceRegistered === "approved" && licence ? (
                    <>
                      <Nav.Link className="text-light" href="#action3">
                        <Link className='text-light text-decoration'  to="/licence-history">Renew Licence</Link>
                      </Nav.Link>
                      <Nav.Link className="text-light" href="#action3">
                        <Link className='text-light text-decoration'  to="/renew-licence">Licence History</Link>
                      </Nav.Link>
                    </>
                    
                  ) : (
                    isLicenceRegistered === "approved" && (
                      <Nav.Link className='text-light'>
                        <Link className='text-light text-decoration' 
                        to="/register-licence">Apply For Licence</Link>
                      </Nav.Link>
                    )
                  )}

                <Nav.Link className="text-light">
                  <Link
                    className="text-light text-decoration-none py-"
                    to="/manage-inspections"
                  >
                    Premises
                  </Link>
                </Nav.Link>

                <Nav.Link className="text-light">
                  <Link
                    className="text-light text-decoration-none py-"
                    to="/payments"
                  >
                    Payments
                  </Link>
                </Nav.Link>
                <Nav.Link className="text-light">
                  <Link
                    className="text-light text-decoration-none py-"
                    to="/logout"
                  >
                    Logout
                  </Link>
                </Nav.Link>
              </Nav>
            </nav>
          </Container>
        </Navbar>

        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
          <nav
            class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
            id="navbarBlur"
            navbar-scroll="true"
          >
            <div class="container-fluid py-1 px-3">
              <nav aria-label="breadcrumb">
              </nav>
              
            </div>
          </nav>
        </main>
      </div>
    );
  }
}

export default Sidebar;
