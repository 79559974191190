import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const currentPath = window.location.pathname;

export function fetchLicenceRRR() {
  let rrr = localStorage.getItem("rrr") || "";
  let licenseId = localStorage.getItem("license_id");
  let countRetry = 0;

  if (rrr.length <= 4) {
    axios
      .post(`${baseUrl}/v2/license/license/${licenseId}/generate-rrr`, null, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const responseJson = response.data;
        if (responseJson.rrrgenerated) {
          localStorage.setItem("rrr", responseJson.rrr);
        } else if (!responseJson.rrrgenerated) {
          // only retry fetching RRR only 3 times else pop-up modal and route ro /dashboard
          if (countRetry >= 2) {
            Swal.fire({
              title: "Error",
              text: "Contact the administrator",
              icon: "error",
              confirmButtonText: "OK",
            }).then(() => {
              window.location.href = `${currentPath}#/dashboard`;
            });
          }
          // Retry fetching RRR after a delay
          setTimeout(fetchLicenceRRR, 1000); // 2 seconds delay before retrying
          countRetry = countRetry + 1;
        }
      })
      .catch((error) => {
        console.error("Error fetching RRR:", error);
      });
  }
}

export function makePayment(paymentType, endpoint) {
  if (
    paymentType === "licence" &&
    (localStorage.getItem("rrr")?.length <= 4 || !localStorage.getItem("rrr"))
  ) {
    fetchLicenceRRR();
  }

  const url = `${baseUrl}/v2/remita/checkRRRStatus/${localStorage.getItem(
    "rrr"
  )}`;

  fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => await res.json())
    .then((res) => {
      if (res?.paymentStatus === "complete") {
        window.location.href = `${currentPath}#/dashboard`;
        window.location.reload();
      } else {
        const statusCheckResponse = res;

        if (
          !statusCheckResponse ||
          statusCheckResponse.isSuccessful === false
        ) {
          Swal.fire({
            title: "Error",
            text:
              statusCheckResponse?.message ??
              "Unable to contact Remita. Please try again.",
            icon: "error",
            confirmButtonText: "OK",
          });

          return;
        }

        if (statusCheckResponse?.paymentStatus === "pending_confirmation") {
          window.location.href = `${currentPath}#/pending-payment`;
        } else if (statusCheckResponse?.paymentStatus === "failed") {
          let reason =
            statusCheckResponse?.remitaMessage.toLowerCase() ??
            "reason was not unspecified.";
          Swal.fire({
            title: "Error",
            text: "Remita failed to launch. Reason cited: '" + reason + "'",
            icon: "error",
            confirmButtonText: "OK",
          });

          return;
        } else {
          var paymentEngine = window.RmPaymentEngine.init({
            key: process.env.REACT_APP_REMITA_KEY,
            processRrr: true,
            transactionId: uuidv4(),
            extendedData: {
              customFields: [
                {
                  name: "rrr",
                  value: statusCheckResponse.rrr,
                },
              ],
            },
            onSuccess: (response) => {
              localStorage.setItem("paymentSuccessful", response, true);
              if (paymentType === "registration") {
                checkRegistrationPaymentStatus(endpoint);
              }
            },
            onError: (error) => {
              console.warn("callback onError", error);
            },
            onClose: () => {},
          });

          paymentEngine.openIframe();
        }
      }
    });
}

const checkRegistrationPaymentStatus = async (endpoint) => {
  try {
    const res = await axios.post(
      `${baseUrl}/v2/registration/register/${endpoint}`,
      { userId: JSON.parse(sessionStorage.getItem("registration:userId")) }
    );

    if (res?.data?.paymentInformation?.paymentStatus === "complete") {
      window.location.href = `${currentPath}#/dashboard`;
      window.location.reload();
    } else if (
      res?.data?.paymentInformation?.paymentStatus === "pending_confirmation"
    ) {
      window.location.href = `${currentPath}#/pending-payment`;
    }
  } catch (error) {
    if (error?.response?.data?.paymentStatus === "pending_payment") {
      Swal.fire({
        title: "Error",
        text: "Pls proceed to payment",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      Swal.fire({
        title: "Error",
        text:
          `Payment ${error?.response?.data?.paymentStatus}` ||
          "Contact the administrator",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }
};
